import Vue from 'vue'
import { Component,  } from 'vue-property-decorator'
import {  mapGetters } from 'vuex'
import * as types from '@survey/store/types'
import {  SurveyStatisticsExamples,SurveyDetailHeader, SurveyReadQuestion ,SurveyReadExamples} from '@survey/components'
import api from '@survey/api'
import StatisticsModalView from '@survey/views/ModalViews/SurveyStatisticsDetailModalView.vue'


 

@Component({
    components: { 
        StatisticsModalView,
        SurveyStatisticsExamples,
        SurveyDetailHeader, 
        SurveyReadQuestion,
        SurveyReadExamples },
    computed: {
        ...mapGetters({
            surveyDetail: types.SURVEY_STATISTICS,
            menuPermission : types.SURVEY_MENU_PERMISSIONS,
        })
    }
})
export default class SurveyStatisticsView extends Vue {
    surveyDetail!: survey.SurveyDetailResponse
    menuPermission!: survey.MenuPermissions
    surveyStatistics : Array<survey.SurveyStatisticsItem> = []
    surveyStatisticsDetailLists : Array<survey.SurveyStatisticsDetailItem> = []
    participantCount : number = 0
    showModal : boolean = false
    isPopup : boolean = false


    async created() {
         
        //팝업인지 확인
        this.isPopup = this.$route.path.toLowerCase().includes('popup')

        this.setPage({
            
            title: this.$t('설문조사_결과').toString()
        })
        //읽기화면에서 통계보기로 이동한 경우가 아니면 모두 잘못된 접근으로 판단
        // debugger
        
        if(this.surveyDetail.surveyDetailInfo == null){
            await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
            this.goBack()
            return
             
        }
        
         
        this.initStatisticsData()
        this.initParticipantCount()
     
    }

    initParticipantCount(){
        
        this.participantCount = this.surveyDetail.surveyDetailInfo.completedUserCount
        //잘못 계산함 대상인원을 전체로 보는게 아니라 참여인원을 전체로 봐야 함
        return
        let surveyId = this.surveyDetail.surveyDetailInfo.surveyId as number
        api.survey.getParticipantCount(surveyId).then(result =>{
            
            this.participantCount = result
        })
    }

    initStatisticsData(){
        let surveyId = this.surveyDetail.surveyDetailInfo.surveyId as number

        api.survey.getSurveyStatisticsLists(surveyId)
        .then(result =>{
             
            
            this.surveyStatistics = result.surveyStatisticsLists.map(item =>{
                if(item.isMultipleChoice){
                    let question = this.surveyDetail.questions.filter(questionItem =>{
                        return questionItem.questionInfo.surveyQuestionId == item.surveyQuestionId
                    })
                    var example = question[0].examples.filter(exmpleItem =>{
                        return exmpleItem.surveyExampleId == parseInt(item.surveyAnswer)
                    })

                    return {
                        ...item,
                        exampleText : example[0].exampleText
                    }
                }else{ // 주관식
                    return {
                        ...item,
                        exampleText : ''
                    }
                }
               
            })
            //여기서 질문 텍스트를 추가해줘야 됨
        })
    }

    setStatisticsData(questionId : number){
        
        return this.surveyStatistics.filter(item =>{
            return item.surveyQuestionId == questionId
        })
    }

    
    goBack() {
        // 팝업일 때 액션 달라져야 함
         
 
        if(this.isPopup){
            window.close()
        }else{
            this.$router.push({
                name: "SurveyList",
                query: {
                    MENUID: this.$route.query.MENUID
                }
            })
        }



    }

    
    popupModal(data : survey.SurveyStatisticsListDetailResponse){
        // alert('receive event')
        console.log(data)
        this.surveyStatisticsDetailLists = data.surveyStatisticsDetailLists
        this.showModal = true

    }

     
    closeModal(){
        // 디테일 값 초기화
        this.surveyStatisticsDetailLists = []
        this.showModal = false
        
    }


    async goUserStatistics(){
        await (window as any).alertAsync(this.$t('사용자별_통계_이동').toString())
    }

    excelExport(){
        api.survey.downloadSurveyStatisticsExcelData(<number>this.surveyDetail.surveyDetailInfo.surveyId)
    }
}
