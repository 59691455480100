import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ModalLayout } from '@appbase/templates'
import * as dateUtil from '@appbase/util/date-helper'



@Component({
    components: { ModalLayout },

})
export default class SurveyStatisticsDetailModalView extends Vue {
    @Prop({ type: Boolean }) show!: boolean
    @Prop({ type: Array }) value!: Array<survey.SurveyStatisticsDetailItem>

    displayItem : Array<survey.SurveyStatisticsDetailItem> = []
    keyword : string = ""

    // created() {
    //     this.displayItem = this.value
    // }

    close(){
        
        this.$emit('close')
    }
    
    convertDate(date: string) {
        return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd H:i:s", new Date(date))
       
    }

    onChangeKeyword(){
        let parseKeyword = this.keyword.toLowerCase() // 대소문자 구분 X

        if(this.keyword == ""){
            this.displayItem = this.value
        }else{
            this.displayItem = this.value.filter(item =>{
                return item.surveyAnswer.toLowerCase().includes(parseKeyword) || 
                       item.displayName.toLowerCase().includes(parseKeyword)
            })
        }
    }

    @Watch("value")
    onChangedValue(){
        //초기화 진행
       this.init()
    }

    init(){
        this.displayItem = this.value
        this.keyword = ""
    }
}
